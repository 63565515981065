import React, { useEffect, useState } from 'react';
import '../../styles/PersonnalTraining.css';
import banniere from '../../img/banniere.png';
import DashBoardNavBar from "../DashboardNavbar";
import firebase from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { FaInstagram, FaEnvelope } from 'react-icons/fa';

const PersonnalTraining = ({user}) => {
    const [userName, setUserName] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate.toDate();
                    setUserName(data.username);
                    if (endSubscriptionDate < new Date()) {
                        navigate('/');
                    }
                }
            }else{
                navigate('/dashboard')
            }
        };
        checkPlanValidity();
    }, []);

    return (
        <>
            <DashBoardNavBar userName={userName} boldItem={'personnalTraining'}/>
            <div className="modern-container">
                <div className="hero-section">
                    <img src={banniere} alt="Personal Training Banner" className="hero-image" />
                    <div className="hero-overlay">
                        <h1>Professional Goals Coaching</h1>
                        <p className="hero-description">
                            Transformez votre potentiel en succès avec notre coaching personnalisé. 
                            Ensemble, définissons et atteignons vos objectifs professionnels.
                        </p>
                    </div>
                </div>
                
                <div className="content-section">
                    <div className="features">
                        <div className="feature-card">
                            <h3>Coaching Personnalisé</h3>
                            <p>Des séances adaptées à vos besoins et objectifs spécifiques</p>
                        </div>
                        <div className="feature-card">
                            <h3>Suivi Régulier</h3>
                            <p>Un accompagnement continu pour garantir votre progression</p>
                        </div>
                        <div className="feature-card">
                            <h3>Résultats Concrets</h3>
                            <p>Des stratégies éprouvées pour atteindre vos objectifs</p>
                        </div>
                    </div>

                    <div className="contact-section">
                        <h2>Contactez-moi</h2>
                        <div className="social-links">
                            <a href="https://www.instagram.com/thy_jasonest/" target="_blank" rel="noopener noreferrer" className="social-link">
                                <FaInstagram /> @thy_jasonest
                            </a>
                            <a href="mailto:thyjasonest@gmail.com" className="social-link">
                                <FaEnvelope /> thyjasonest@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonnalTraining;
